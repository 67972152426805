import { formatPathString } from "../../../../../util/util";
import { formatBlogDetails } from "./helper-utility";

export async function getBlogById(blogId) {
  try {
    const res = await fetch(`${process.env.GATSBY_BLOGCMS_URL}/api/blogs?filters[id][$eq]=${blogId}&populate[blogHeroImage][populate]=*&populate[blogContent][populate]=*&populate[linkedBlogs][populate]=*`);
    if (!res.ok) {
      return null
    }
    const result = await res.json()
    if (result.data?.length > 0) {
      const resultData = result.data?.[0]?.attributes? result.data?.[0]?.attributes: result.data?.[0]      
      let blog = formatBlogDetails({ id: result.data?.[0]?.id, ...resultData })
      return {
        blogContent: resultData?.blogContent,
        blogDetails: blog,
        relatedBlogs: blog.relatedBlogs,
      }
    } else {
      return null
    }
  } catch (error) {
    return null
  }
}


export async function getBlogsList() {
  try {
    const blogsRes = await fetch(`${process.env.GATSBY_BLOGCMS_URL}/api/blogs?sort[0]=publishedDate:desc&pagination[pageSize]=9999&populate=*`)
    if (!blogsRes.ok) {
      return {
        allBlogs: [],
        blogsLoaded: false
      }
    }
    const response = await blogsRes.json()
    const blogsList = response?.data?.map(blog => {
      const blogData = blog?.attributes? blog?.attributes: {...blog}
      const formattedBlog = formatBlogDetails({ id: blog.id, ...blogData })
      const categories = blog?.categories
      let formatedPath = formatPathString(formattedBlog?.blogTitle);
      return { ...formattedBlog, blogUrl: `/blog/${formatedPath}/`, categories }
    })
    return {
      allBlogs: blogsList?.filter(blog => blog?.environment?.includes(process.env.GATSBY_ENV)),
      blogsLoaded: true,
      categoriesLoaded: true
    }
  } catch (error) {
    return {
      allBlogs: [],
      blogsLoaded: false
    }
  }


}


export async function getCategories() {
  try {
    const res = await fetch(`${process.env.GATSBY_BLOGCMS_URL}/api/categories?&populate=*`);
    if (!res.ok) {
      return {
        categoriesLoaded: false,
        failedCategoriesApi: true,
        categories: []
      }
    } else {
      const groupsResponse = await res.json()
      
      const groups = [
        ...groupsResponse?.data?.filter(a => a.categoryKey === 'recent'),
        ...groupsResponse?.data?.filter(a => a.categoryKey !== 'recent')
      ]
      return {
        categoriesLoaded: true,
        failedCategoriesApi: false,
        categories: groups,
      }
    }
  } catch (error) {
    return {
      categoriesLoaded: false,
      failedCategoriesApi: true,
      categories: []
    }
  }


}
