import React from "react"
import styled from "styled-components";
import { BlogListStyled } from "./blog-list-cards";
import Skeleton from "react-loading-skeleton";

export function BlogListCardsSkeleton({ blogNumber }) {
    return (
        <BlogListStyled className="kuda-section--100 kuda-spacing--inner kuda-disclaimer--section container">
            <BlogListSkeletionStyled className="kuda-press--cards flex justify-between flex-wrap">
                {[...Array(blogNumber)].map((item, i) => (
                    <div key={i}
                        className="kuda-single--press w-100">
                        <div className="kuda-blog-card-image">
                            <Skeleton width={'100%'} height={'100%'} />
                        </div>
                        <div className="kuda-press--wrap">
                            <div className="kuda-press--info flex justify-between align-center">
                                <Skeleton width={50} height={15} />
                                <Skeleton width={50} height={15} />
                            </div>
                            <div className="kuda-press--details">
                                <h2 className="text-xlbold color-primary title-bottom--spacing kuda-press--heading">
                                    <Skeleton width={'100%'} height={25} />
                                </h2>
                                <p className="color-black kuda-press--subheading">
                                    <Skeleton width={'100%'} height={25} />
                                </p>
                            </div>
                        </div>
                    </div>
                ))}


            </BlogListSkeletionStyled>
        </BlogListStyled>
    )
}
export const BlogListSkeletionStyled = styled.div`
animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}  
`