import React from "react"
import styled from "styled-components";
import { SearchWrapper } from "./search-wrapper-input";

export default function BlogCategoriesSection(props) {
    const { selectedFilter, onFilterGroupChanged, onSearchValueChanged, searchValue, onSearchInputChanged } = props
    return (
        <BlogCategoryContainer>
            <div className="kuda-section--100 kuda-disclaimer--section">
                <FilterGroupStyled>
                    <div className="filter-divider">              <div className="flex flex-column flex-lg-row justify-between">
                        <div className=" flex flex-row justify-between">
                            <div className="flex flex-row filters">{props.filters?.map((filter, index) => {
                                const isSelected = filter?.categoryKey === selectedFilter?.categoryKey;
                                return (<div key={index} className={`filter  ${isSelected ? "color-secondary text-xbold" : "cursor-pointer color-primary text-bold"}`} onClick={() => onFilterGroupChanged(!isSelected ? filter : null)}>
                                    {filter?.categoryName}
                                </div>)
                            })}</div>
                        </div>
                        <div className="flex flex-row justify-between">
                            <SearchWrapper submit={onSearchValueChanged} onInputChanged={onSearchInputChanged} inputValue={searchValue} />
                        </div>
                    </div>
                    </div>

                </FilterGroupStyled>
                <div className="kuda-section--heading filter-displayName text-left text-xlbold color-primary bottom--spacing  mt-2 mt-lg-3">
                    {selectedFilter?.displayName}
                </div>
            </div>

        </BlogCategoryContainer>
    )
}

export const BlogCategoryContainer = styled.div`

    .kuda-disclaimer--section {
        margin-top: 10px
    }
    .kuda-spacing--inner {
        margin-top: 10px;
    }
    .filter-displayName {
        font-size: 36px;
        line-height: 50px; 
    }
    ::-webkit-scrollbar {
        display: none;
    }
    .filters {
        overflow-x: scroll;        
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;     
    
    }
    @media only screen and (max-width: 767px) {
        .filter-displayName {
            font-size: 28px;
            line-height: 37px;
        }
    }
 
    @media only screen and (max-width: 992px) {
        .filter-displayName {
            margin-top: 25px;
        }
        .filter-divider {
            border-bottom: 1px solid transparent;
        }
    }
    @media only screen and (max-width: 501px) {
       .filters .filter {
                font-size: 14px;
                padding-bottom: 14px;
                padding-top: 14px;
                white-space: nowrap;
            }
    }

}
`


export const FilterGroupStyled = styled.div`
    padding-bottom: 10px;

    @media only screen and (max-width: 991px) {
        padding-bottom: 20px;
    }
       
    .filter {
        font-size: 14px;
        padding-bottom: 23px;
        padding-top: 23px;

    }
    div.filter:not(:first-child) {
        margin-left:43px;
    }
    .filter-divider {
        width: 100%;
        border-bottom: 1px solid rgba(223, 227, 255, 0.5);
        background: #FFF;
    }

    @media only screen and (max-width: 767px) {
        .filter-divider {
            border-bottom: 1px solid transparent;
            background: transparent;
        
        }
    }
    @media only screen and (max-width: 501px){
        padding-bottom: 16px;
    }
`