import React from "react";
import styled from "styled-components";
import SearchIcon from "../../../../../assets/img/no-result-search.inline.svg"
export function BlogListEmptyState(props) {
    return (
        <BlogListEmptyStateStyled className="kuda-section--100 kuda-spacing--inner kuda-disclaimer--section container">
            <div className="flex flex-column justify-center">
                <div className="flex align-center justify-center bottom--spacing">
                    <SearchIcon />
                </div>
                <div className="flex align-center justify-center no-result-text color-black bottom--spacing ">
                    {props.title?? 'No results found'}
                </div>
                <div className="flex align-center justify-center no-result-text color-black bottom--spacing ">
                 <a href={'/'} className={`kuda-cta-outline cta_button`} rel="noopener noreferrer" >Go Home</a>

                </div>
            </div>

        </BlogListEmptyStateStyled>
    )
}
const BlogListEmptyStateStyled = styled.div`
    .no-result-text {
        font-size: 28px;
        font-weight: 800;
        line-height: 34px;
        text-align: left;

    }


`