import React, { useEffect, Fragment } from "react"
import CTA from "../general/cta"
import { scrollToElement } from "../../../utility/utils"
import { useState } from "react"
import BlogCategoriesSection from "./components/blog-categories-section"
import { BlogListCards } from "./components/blog-list-cards"
import { BlogListCardsSkeleton } from "./components/blog-list-cards-skeleton"
import { BlogListEmptyState } from "./components/blog-list-empty-state"
import { getBlogsList, getCategories } from "./blog-details/api-utility"
const Blog = () => {
  const [selectedFilter, setSelectedFilter] = useState()
  const [state, setState] = useState({
    failedCategoriesApi: false,
    categoriesLoaded: false,
    isBlogsLoading: true,
    isPageLoading: true,
    allBlogs: [],
    categories: []
  })
  const [searchValue, setSearchValue] = useState("")
  const [filteredBlogs, setFilteredBlogs] = useState([])
  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
    const fetchData = async () => {
      const categories = await getCategories()
      let data = {
        ...state,
        ...categories
      }
      if (data?.failedCategoriesApi) {
        setState({
          ...state,
          ...data,
          blogsLoaded: false,
          isBlogsLoading: false,
          isPageLoading: false,
        })
      } else {
        const categories = data?.categories ? data?.categories : [];
        if (categories.length > 0) {
          const selectedGroup = categories?.[0]
          setSelectedFilter(selectedGroup)
          setState({ ...data })
        }
        const blogResult = await getBlogsList()
        data = {
          ...data, ...blogResult
        }
        setState({
          ...data,
          isBlogsLoading: false,
          isPageLoading: false
        })
      }

    }
    fetchData()
  }, [])
  useEffect(() => {
    if (state.categoriesLoaded) {
      changeFilterGroup(selectedFilter, null)
    }
  }, [state.categoriesLoaded, state.blogsLoaded])

  async function changeFilterGroup(selectedCategoryFilter, searchInputValue) {

    const envBlogs =state?.allBlogs.filter(blog =>blog?.environment?.includes(process.env.GATSBY_ENV)) 

    if (selectedCategoryFilter) {
      const blogs = await envBlogs?.filter(blog => {
        const selectedBlogByGroup = blog?.categories?.find(category => category.categoryKey === selectedCategoryFilter.categoryKey)?.categoryKey
        const selectedBlogByName = searchInputValue ? blog.blogTitle.toLowerCase().includes(searchInputValue.toLowerCase()) : true
        if (selectedBlogByName && selectedBlogByGroup) {
          return blog
        }
      })
      setFilteredBlogs(blogs)
      setState({ ...state, isBlogsLoading: false })
    }
  }
  return (
    <Fragment>
      <div className="kuda-inner--heading">
        <div className="kuda-section--inner">
          <div className="kuda-section--100 inner-spotlight--heading text-center has-heading--divider">
            <h1 className="text-xl text-xlbold color-primary title-bottom--spacing">
              Blog
            </h1>
            <div className={`kuda-section--100 flex justify-center`}>
              <div className="kuda-main--wrap flex flex-column align-center mt-0">
                <span className="card--heading text-semi-bold color-black text-center">
                  Follow our train of thought, get fresh updates and dive into eye-opening money guides.
                </span>
              </div>
            </div>
          </div>
          <div className="kuda-section--100">
            {
              state?.isPageLoading ? <BlogListCardsSkeleton blogNumber={2} /> : <>
                {state?.failedCategoriesApi ? (
                  <BlogListEmptyState
                    title={"Internal server error"}
                    subTitle={"Sorry, an unexpected error occured. Please try again."}
                  />
                ) : (
                  <>
                    <BlogCategoriesSection
                      filters={state.categories}
                      selectedFilter={selectedFilter}
                      searchValue={searchValue}
                      onFilterGroupChanged={value => {
                        if (value) {
                          setState({ ...state, isBlogsLoading: true })
                          setSearchValue('')
                          setSelectedFilter(value)
                          changeFilterGroup(value, null)
                        }
                      }}
                      onSearchValueChanged={() => {
                        if (!state.isBlogsLoading) {
                          setState({ ...state, isBlogsLoading: true })
                          changeFilterGroup(selectedFilter, searchValue)
                        }

                      }}

                      onSearchInputChanged={setSearchValue}
                    />

                    {state.isBlogsLoading ? (
                      <BlogListCardsSkeleton blogNumber={2} />
                    ) : filteredBlogs?.length > 0 ? (
                      <BlogListCards blogs={filteredBlogs} />
                    ) : (
                      <BlogListEmptyState />
                    )}
                  </>
                )}
              </>
            }
          </div>
        </div>

      </div>



      <CTA />
    </Fragment>
  )
}

export default Blog
