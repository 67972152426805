import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Blog from "../components/body/pages/blog"

const BlogListPage = () => {
  return (
    <Layout>
      <SEO
        canonical={"https://kuda.com/blog/"}
        title="Kuda’s Blog | Follow Kuda’s thoughts, get updates, news and dive into helpful information | Kuda"
        description="Recent news, Product Updates, Marketing Insights and Company Information from Kuda"
      />
      <Blog/>
    </Layout>
  )
}

export default BlogListPage
